<template>
  <div class="card">
    <div class="card-body">
      <div class="mb-3">
        <label for="invoice-number" class="form-label">Invoice Number</label>
        <input id="invoice-number" type="text" class="form-control" v-model="invoiceNumber" disabled />
      </div>

      <div class="mb-3">
        <label for="invoice-date" class="form-label">Invoice Date</label>
        <input id="invoice-date" type="date" class="form-control" v-model="invoiceDate" />
      </div>

      <div class="mb-3">
        <label for="client" class="form-label">To Client</label>
        <input id="client" type="text" class="form-control" v-model="client" />
      </div>

      <div class="mb-5">
        <label for="client-city" class="form-label">Client City</label>
        <input id="client-city" type="text" class="form-control" v-model="clientCity" />
      </div>

      <h3>Barang</h3>
      <hr>

      <div v-for="(item, index) in items" :key="index" class="mb-3 row">
        <div class="col">
          <label class="form-label">Quantity</label>
          <input type="number" class="form-control" v-model.number="item.quantity" @input="updateSubtotal(index)" />
        </div>
        <div class="col">
          <label class="form-label">Item Type</label>
          <input type="text" class="form-control" v-model="item.itemType" />
        </div>
        <div class="col">
          <label class="form-label">Price</label>
          <input type="number" class="form-control" v-model.number="item.price" @input="updateSubtotal(index)" />
        </div>
        <div class="col">
          <label class="form-label">Subtotal</label>
          <input type="number" class="form-control" v-model.number="item.subTotal" disabled />
        </div>
        <div class="col-auto d-flex align-items-end">
          <button @click="removeItem(index)" class="btn btn-danger">Remove</button>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button @click="addItem" class="btn btn-success">Add Item</button>
      </div>

      <div class="d-flex justify-content-end mt-4">
        <button @click="submitInvoice" class="btn btn-primary">Submit Invoice</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Swal from 'sweetalert2';
const emit = defineEmits(['add-invoice']);

const generateInvoiceNumber = () => {
  const date = new Date();
  const year = date.getFullYear().toString().padStart(2, '0'); // Ensure two digits
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adjust for zero-based indexing
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

  return `INV-${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
};

const invoiceNumber = generateInvoiceNumber();
const invoiceDate = ref(new Date().toISOString().substring(0, 10)); // Default to today's date
const client = ref('');
const clientCity = ref('');
const items = ref([{ quantity: 1, itemType: 'Suun Srimpi', price: 0, subTotal: 0 }]);

const addItem = () => {
  items.value.push({ quantity: 1, itemType: 'Suun Srimpi', price: 0, subTotal: 0 });
};

const removeItem = (index) => {
  items.value.splice(index, 1);
};

const updateSubtotal = (index) => {
  const item = items.value[index];
  item.subTotal = item.quantity * item.price;
};

const calculateTotal = () => {
  return items.value.reduce((total, item) => total + item.subTotal, 0);
};

const submitInvoice = () => {
  
  Swal.fire({
    title: 'Submitting Invoice...',
    text: 'Please wait...',
    showConfirmButton: false,
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  const newInvoice = {
    invoiceNumber: invoiceNumber,
    date: invoiceDate.value,
    client: client.value,
    city: clientCity.value,
    total: calculateTotal(),
    items: items.value.map(item => ({
      description: item.itemType, 
      quantity: item.quantity,
      unitPrice: item.price,
      total: item.subTotal,
    })),
  };
  emit('add-invoice', newInvoice);
};
</script>
