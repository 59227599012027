import jsPDF from 'jspdf';
import 'jspdf-autotable';

export function generateInvoicePDF(invoice, items, tempo) {
  const doc = new jsPDF();

  // Tambahkan Judul
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(20);
  doc.text('NOTA PENJUALAN', 14, 22);

  // Detail Faktur
  doc.setFontSize(12);
  doc.setFont('helvetica', 'normal');
  doc.text(`${invoice.invoiceNumber}`, 14, 30);
  doc.text(`Tanggal: ${invoice.date}`, 14, 36);

  if(tempo != ""){
    doc.text(`Tempo Pembayaran: ${tempo}`, 14, 45);
  }

  // Right-aligned "Pelanggan" and "Kota"
  const rightAlignX = 196; // Right margin of the page
  doc.text(`Pelanggan: ${invoice.client}`, rightAlignX, 30, { align: 'right' });
  doc.text(`Kota: ${invoice.city}`, rightAlignX, 36, { align: 'right' });

  // Tambahkan garis pemisah
  doc.line(14, 52, 196, 52);

  // Konten Tabel (jika Anda memiliki beberapa item)
  const tableColumn = ["Barang", "Kuantitas", "Harga Satuan", "Total"];
  const tableRows = [];

  items.forEach(item => {
    tableRows.push([
      item.description,
      item.quantity,
      `Rp.${item.unitPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      `Rp.${item.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
    ]);
  });

  // Draw the table
  doc.autoTable(tableColumn, tableRows, { 
    startY: 60,
    styles: { fontSize: 13 }
  });

  // Check if the invoice status is "Lunas" and add a watermark with a box
  if (invoice.status === "Lunas") {
    // Set font and color for the watermark text
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(50);
    doc.setTextColor(255, 0, 0); // Red color

    // Get the width and height of the page
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Calculate the text width and height for centering and surrounding box
    const text = "LUNAS";
    const textWidth = doc.getTextWidth(text);
    const textHeight = 50 / 2; // Approximation of text height

    // Calculate the position for the text and box
    const xPos = pageWidth / 2 - textWidth / 2;
    const yPos = pageHeight / 2 + textHeight / 2;

    // Draw the red box around the text
    doc.setDrawColor(255, 0, 0); // Red border color
    doc.setLineWidth(2); // Border thickness
    doc.rect(xPos - 10, yPos - textHeight, textWidth + 20, textHeight); // Rect dimensions

    // Add the "LUNAS" text inside the box
    doc.text(text, pageWidth / 2, pageHeight / 2, {
      align: 'center',
      baseline: 'middle',
    });

    // Reset text color to black after the watermark
    doc.setTextColor(0, 0, 0);
  }

  // Jumlah Total
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(15);
  doc.text(`Total: Rp.${invoice.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`, 14, doc.previousAutoTable.finalY + 10);

  // Footer
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(12);

  doc.setFont('helvetica', 'bold');
  doc.text('Pembayaran dapat di transfer ke', 14, doc.previousAutoTable.finalY + 20);
  doc.text('Rek. BCA 863 046 8583', 14, doc.previousAutoTable.finalY + 25);
  doc.text('a/n axel sachio kentaro dealon', 14, doc.previousAutoTable.finalY + 30);

  doc.text('Terima kasih', 14, doc.previousAutoTable.finalY + 40);
  doc.setFont('helvetica', 'normal');

  // Simpan PDF
  doc.save(`${invoice.invoiceNumber}.pdf`);
}
