import axios from 'axios';

const baseURL = 'https://gostore.eldridgerexroy.com/gostore';

const gostoreService = axios.create({
  baseURL: baseURL,
});

async function getToken() {
  try {
    const response = await axios.get(`${baseURL}/gettoken`);
    const token = response.data.token; // Assuming the token is in response.data.token

    gostoreService.defaults.headers.common['Token'] = token;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw new Error('Could not fetch the token');
  }
}

async function fetchData() {
  await getToken(); 
  try {
    const response = await gostoreService.get('/suun');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

async function deleteData(key) {
  await getToken(); 
  try {
    const response = await gostoreService.delete('/suun/'+key);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

async function searchData(key) {
  await getToken(); 
  try {
    const response = await gostoreService.get('/suun/'+key);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

async function postData(data) {
  await getToken(); 
  try {
    const response = await gostoreService.post('/suun', data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
}

async function putData(data) {
  await getToken(); 
  try {
    const response = await gostoreService.put('/suun', data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
}

export default {
  fetchData,
  postData,
  searchData,
  deleteData,
  putData
};
