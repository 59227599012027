<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form @submit.prevent="handleLogin" role="form">
                    <div class="mb-3">
                      <argon-input
                        v-model="email"
                        id="email"
                        type="text"
                        placeholder="Email"
                        name="email"
                        size="lg"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        v-model="password"
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                      />
                    </div>
                    <div class="text-center">
                      <argon-button
                        @click="handleLogin"
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                        >Sign in</argon-button
                      >
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Version: {{ version }}
                  </p>
                  <p v-if="isServerRunning" class="text-success">
                    Server is running.
                  </p>
                  <p v-else class="text-danger">
                    Server is not down, please check Backend.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg&quot;);
                  background-size: cover;
                "
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4 class="mt-5 text-white font-weight-bolder position-relative">
                  "Attention is the new currency"
                </h4>
                <p class="text-white position-relative">
                  The more effortless the writing looks, the more effort the
                  writer actually put into the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup>
import { onBeforeUnmount, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Navbar from "@/views/components/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import Swal from 'sweetalert2';

const body = document.getElementsByTagName("body")[0];
const store = useStore();
const router = useRouter();

// Form data
const email = ref("");
const password = ref("");
// const rememberMe = ref(false);

// Get version from store
const version = store.state.version || '1.0.0';

// Server status
const isServerRunning = ref(false);

const checkServerStatus = async () => {
  try {
    const response = await fetch('https://gostore.eldridgerexroy.com/gostore/gettoken');
    isServerRunning.value = response.ok;
  } catch (error) {
    isServerRunning.value = false;
  }
};

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");

  // Check server status on mount
  checkServerStatus();
});

onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});

const handleLogin = () => {
  if (email.value === "agus" && password.value === "agus1616") {
    store.state.isLogin = true;
    router.push("/dashboard");

    Swal.fire({
      title: 'Success!',
      text: 'You have successfully logged in.',
      icon: 'success',
      confirmButtonText: 'Continue'
    });
  } else {
    Swal.fire({
      title: 'Error!',
      text: 'Invalid credentials. Please try again.',
      icon: 'error',
      confirmButtonText: 'Ok'
    });
  }
};
</script>
